<template>
    <Breadcrumb page-title="Credit Memo Approval" page-section="Pages"> </Breadcrumb>
     <section class="content">
        <div class="box box-info">
            <div class="box-header with-border" >
                <span class="fa-stack new-account-icon"><i class="fa fa-user"></i><i class="fa fa-commenting icon-plus"></i></span>
                <h3 class="box-title head-title-padding">Logged In Agents</h3>
                
            </div>
            <div class="box-body">
                <div class="box box-primary">
                    <div class="box-header with-border">
                      <h3 class="box-title">Biller &amp; Reason Details</h3>
                    </div>
                    <div class="box-body"> 
                      <div class="row">
                        <div class="col-md-12 form-horizontal">
                          <div class="form-group">
                            <label class="col-sm-12 control-label text-left"></label>
                          </div>  
                          <div class="form-group">
                            <label class="col-sm-2 control-label text-left">Biller Company Name</label>
                            <div class="col-sm-6">
                              <input type="text" class="form-control" disabled="">
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-sm-2 control-label text-left">B‎iller Code</label>
                            <div class="col-sm-6">
                              <input type="text" class="form-control" disabled="">
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-sm-2 control-label text-left">Shipment Type</label>
                            <div class="col-sm-6">
                              <select class="form-control" disabled=""><option label="" value="">Select a value</option></select>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-sm-2 control-label text-left">Reason Category</label>
                            <div class="col-sm-6">
                              <select class="form-control" disabled=""><option label="" value="">Select a value</option></select>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-sm-2 control-label text-left">Reason Description</label>
                            <div class="col-sm-6">
                              <textarea class="form-control" disabled=""></textarea>
                            </div>
                          </div>
                        </div>
                      </div>   
                    </div> 
                  </div>

                  <div class="box box-primary">
                    <div class="box-header with-border">
                      <h3 class="box-title">Credit Details</h3>
                    </div>
                    <div class="box-body"> 
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row"> 
                            <div class="col-md-3">
                              <div class="form-group">
                                <label class="col-sm-12 control-label text-left">Pro Number</label>
                                <div class="col-sm-12">
                                  <input type="text" class="form-control" disabled="" value="">
                                </div>
                              </div>
                            </div> 
                            <div class="col-md-3">
                              <div class="form-group">
                                <label class="col-sm-12 control-label text-left">Credit Memo Amount</label>
                                <div class="col-sm-12">
                                  <input type="text" class="form-control" disabled="" value="">
                                </div>
                              </div>
                            </div> 
                            <div class="col-md-3">
                              <div class="form-group">
                                <label class="col-sm-12 control-label text-left">Shipment Date</label>
                                <div class="col-sm-12">
                                  <input type="text" class="form-control" disabled="" value="">
                                </div>
                              </div>
                            </div> 
                          </div>  
                        </div>
                      </div>   
                    </div> 
                  </div>
                  <div class="row mtop-15">
                    <div class="col-md-12">
                      <button type="button" class="btn btn-primary margin-r-5">Approve Credit</button>
                      <button type="button" class="btn btn-danger margin-r-5">Reject Credit</button>
                    </div>
                  </div>
            </div>
        </div>
     </section>
   </template>
   
   <script>
   // @ is an alias to /src
   import Breadcrumb from '../components/Breadcrumb.vue';
  
   export default {
     name: 'Credit Memo Approval Form',
     components: {
       Breadcrumb,
       
     },
  data: function(){
      return{
        segment1 : false,
        segment2 : false,
        segment3 : false,
        segment4 : false
      }
         
  },
   methods: {
    toggleSegmentCollapsedState(i) {
      this['segment'+i] = !this['segment'+i] 
    }
  },
     ready () {
       $(document).ready(function ($) {
       })
     }
   }
   </script>